



















import { FactConfig, ValidationResult } from '@/services/Renderer'
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { FactDescription } from '@/interfaces/engine-exports'
import { shift } from '@uncharted/coverhub-framework'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'
import TextInput from '@/components/TextInput.vue'

import pFactType = shift.productengine.definition.FactType
import cFactType = shift.claimengine.definition.FactType

@Component({
  components: {
    TextInput
  }
})
export default class FieldMixin extends Vue {
  @Prop() fact!: FactDescription
  @Prop() config?: FactConfig
  @Prop({ default: false }) readonly?: boolean
  protected regex?: RegExp
  protected error = false

  get label(): string {
    if (this.config?.label) {
      return this.$t(this.config.label).toString()
    } else {
      return this.fact.name
    }
  }

  get value(): string {
    if (this.config?.value) {
      return this.config.value(this.fact, this.$t.bind(this))
    } else {
      return this.fact.currentValue || this.fact.defaultValue || ''
    }
  }

  get type(): string {
    switch (this.fact.type) {
      case cFactType.EMAIL:
      case pFactType.EMAIL:
        return 'email'
      case cFactType.NUMBER:
      case pFactType.NUMBER:
        return 'number'
      case cFactType.PHONE_NUMBER:
      case pFactType.PHONE_NUMBER:
        return 'tel'
      default:
        return 'text'
    }
  }

  get inputMode(): string {
    switch (this.fact.type) {
      case cFactType.EMAIL:
      case pFactType.EMAIL:
        return 'email'
      case cFactType.NUMBER:
      case pFactType.NUMBER:
        return 'number'
      case cFactType.PHONE_NUMBER:
      case pFactType.PHONE_NUMBER:
        return 'tel'
      default:
        return 'text'
    }
  }

  get disabled(): boolean {
    return this.readonly || this.fact.readonly || this.config?.readonly === true
  }

  get placeholder(): string {
    return this.config?.placeholder && this.$te(this.config?.placeholder) ? this.$t(this.config?.placeholder).toString() : this.config?.placeholder || ''
  }

  mounted() {
    if (this.value) {
      this.validateInput(this.value)
      this.validateChange(this.value)
    }
  }

  protected validateInput(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }
    if (this.regex) {
      validationResult.valid = this.regex.test(value)
    } else if (this.config?.validate) {
      validationResult = this.config.validate(value, this.fact)
    }
    this.fireFactValidation({
      id: this.fact.id,
      valid: validationResult.valid,
      message: validationResult.message
    })
    this.error = !validationResult.valid
    return validationResult
  }

  protected validateChange(value: string): ValidationResult {
    let validationResult: ValidationResult = { valid: true }
    if (this.config?.validateChange) {
      validationResult = this.config.validateChange(value, this.fact, this)
      this.fireFactValidation({
        id: this.fact.id,
        valid: validationResult.valid,
        message: validationResult.message
      })
      this.error = !validationResult.valid
    }
    return validationResult
  }

  get icon(): string | undefined {
    return this.config?.icon
  }

  onInput(value: string) {
    this.validateInput(value)
    this.fireFactChange({
      id: this.fact.id,
      value: value
    })
  }

  onChange(value: string) {
    this.validateChange(value)
    if (this.config?.transformValue) {
      value = this.config.transformValue(value)
    }
    this.fireFactChange({
      id: this.fact.id,
      value: value
    })
  }

  onValidation(valid: boolean) {
    this.fireFactValidation({
      id: this.fact.id,
      valid
    })
  }

  @Emit('fact-validation')
  fireFactValidation(evt: FactValidationEvent) {
    return evt
  }

  @Emit('fact-change')
  fireFactChange(evt: FactChangeEvent) {
    return evt
  }
}
