





















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import debounce from 'lodash.debounce'

@Component
export default class TextInput extends Vue {
  @Prop({ default: '' }) id!: string
  @Prop({ default: '' }) name!: string
  @Prop({ default: '' }) value!: string
  @Prop({ required: true }) label!: string
  @Prop() icon?: string
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) readonly !: boolean
  @Prop({ default: '' }) placeholder!: string
  @Prop() dataTc?: string
  @Prop({ default: 'text' }) type?: string
  @Prop({ default: 'text' }) inputMode?: string
  @Prop({ default: 'text' }) extraStyle?: string
  @Prop({ default: 200 }) debounceTimeout!: number
  private current = ''

  constructor() {
    super()
    this.fireValidation = debounce(
      this.fireValidation.bind(this),
      this.debounceTimeout
    ) as { (v: boolean): boolean }
    this.fireChange = debounce(
      this.fireChange.bind(this),
      this.debounceTimeout
    ) as { (v: string): string }
    this.fireInput = debounce(
      this.fireInput.bind(this),
      this.debounceTimeout
    ) as { (v: string): string }
  }

  mounted() {
    this.current = this.value
  }

  get iconCss(): string | undefined {
    if (this.icon) {
      return `icon-${this.icon}`
    }
  }

  // If upstream value changes due to outside interference
  // it must be updated.
  @Watch('value')
  onWatch(newValue: string) {
    this.current = newValue
  }

  onInput(event: InputEvent) {
    const target = event.target as HTMLInputElement
    this.current = target.value
    this.fireInput(this.current)
  }

  onChange() {
    this.fireChange(this.current)
  }

  onBlur() {
    this.fireBlur(this.current)
  }

  @Emit('validation')
  fireValidation(valid: boolean) {
    return valid
  }

  @Emit('change')
  fireChange(value: string) {
    return value
  }

  @Emit('input')
  fireInput(value: string) {
    return value
  }

  @Emit('blur')
  fireBlur(value: string) {
    return value
  }
}
