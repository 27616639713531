
























































import { Auth } from '@/services/Auth'
import { AuthResult } from '@uncharted/coverhub-framework'
import { Component, Vue } from 'vue-property-decorator'
import { ACTION_SET_EMAIL } from '@/store/modules/user/actionTypes'
import { Action, Getter } from 'vuex-class'
import { LOGIN_FAILED } from '@/constants/common'
import EmailInputRenderer from '@/components/facts/renderers/EmailInputRenderer.vue'
import { FactValidationEvent } from '@/interfaces/FactValidationEvent'
import { FactChangeEvent } from '@/interfaces/FactChangeEvent'

@Component({
  components: {
    EmailInputRenderer
  }
})
export default class SignIn extends Vue {
  @Action(ACTION_SET_EMAIL) setEmail!: (email: string) => void
  @Getter('user/email') email!: string
  @Getter('app/suspendRegistration') suspendRegistration!: boolean
  private pageValid = false
  private clicked = false

  private emailFact = {
    id: 'email',
    currentValue: ''
  }

  private emailConfig = {
    label: 'signIn.label.email',
    placeholder: this.$t('signIn.placeHolder.email')
  }

  created() {
    this.emailFact.currentValue = this.email
  }

  otp() {
    if (this.emailFact) {
      this.clicked = true
      const email = this.emailFact.currentValue

      Auth.authenticate('otp', { identifier: email }).then(
        (res: AuthResult) => {
          if (res.success) {
            this.setEmail(email)
            this.$router.push({ name: 'OTP' })
          } else if (res.error === LOGIN_FAILED) {
            this.$showErrorPopUp(
              this.$t('common.error.loginFailed').toString()
            )
          } else {
            this.$showErrorPopUp(this.$t('emailAuth.error.email').toString())
          }
        }
      )
    }
  }

  onFactValidation(evt: FactValidationEvent) {
    this.pageValid = evt.valid
  }

  onChange(evt: FactChangeEvent) {
    this.emailFact.currentValue = evt.value?.trim()?.toLocaleLowerCase()
  }

  displayErrorMessage() {
    return !this.pageValid && this.emailFact.currentValue?.length > 0
  }
}
