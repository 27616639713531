
import { Component, Mixins } from 'vue-property-decorator'
import FieldMixin from '@/components/facts/FieldMixin.vue'
import { shift } from 'product-engine'

@Component
export default class EmailInputRenderer extends Mixins(FieldMixin) {
  protected regex = /^[a-zA-Z0-9!#$&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/

  created() {
    this.fact.type = shift.productengine.definition.FactType.EMAIL
  }
}
